import React, { createContext, useCallback, useState, useContext } from "react";

const ReportsContext = createContext();

const ReportsProvider = ({ children }) => {
  const getCache = (item) => {
    const res = JSON.parse(localStorage.getItem(`@clube:${item}`));
    return res;
  };

  const setCache = (item, value) => {
    localStorage.setItem(`@clube:${item}`, JSON.stringify(value));
  };

  return (
    <ReportsContext.Provider
      value={{
        setCache,
        getCache,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

function useReports() {
  const context = useContext(ReportsContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { ReportsProvider, useReports };
