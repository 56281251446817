import React, { useRef, useState, useEffect, useCallback } from "react";
import Input from "../components/Input";
import { Form } from "@unform/web";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Spinner,
  Col,
} from "reactstrap";
import api from "../../services/api";
import Switch from "react-bootstrap-switch";
import { useAuth } from "../../context/AuthContext";
import Select from "../../components/Form/SelectInput";
import { Radio } from "@material-ui/core";
import { Preview, RadioGroup, WhatsppDiv } from "./styles";
import apiUzapi from "services/apiUzapi";
import apiGd from "services/apiGd";

function Settings() {
  const notificationAlert = useRef(null);
  const formRef = useRef(null);

  const { user, updateSettings, wppStatus, setWppStatus, getUser } = useAuth();
  const settings = user.settings[0];
  const [benefitType, setBenefitType] = useState(settings.benefit_type);
  const [wppConnected, setWppConnected] = useState(
    JSON.parse(localStorage.getItem("@clube:wppConnected")) || null
  );
  const [takeatName, setTakeatName] = useState(
    JSON.parse(localStorage.getItem("@clube:takeatName")) || null
  );
  const [benefitValue, setBenefitValue] = useState(settings.benefit_value);
  const [typeSelected, setTypeSelected] = useState(settings?.model);
  const [hasBirthday, setHasBirthday] = useState(settings.birthday_auto);
  const [spent, setSpent] = useState({});

  const defaultType = {
    value: typeSelected,
    label: typeSelected === "cashback" ? "Cashback" : "Pontos",
  };

  const token = localStorage.getItem("@clube:token");
  const [loading, setLoading] = useState(false);
  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  let type_options = [
    { value: "cashback", label: "Cashback" },
    { value: "points", label: "Pontos" },
  ];

  async function handleSubmitSettings(data) {
    try {
      setLoading(true);
      if (hasBirthday && (!benefitType || !benefitValue)) {
        notify(
          "tr",
          "danger",
          "Preencha o tipo e valor do benefício dos aniversariantes"
        );
        setLoading(false);
        return;
      }
      let settings = {
        model: typeSelected,
        percent: data.percent.replace(",", "."),
        minimo: data.minimo,
        obs: data.obs,
        birthday_auto: hasBirthday,
        points: data.points,
        expires: parseInt(data.expires),
        benefit_type: benefitType || null,
        benefit_value: benefitValue || null,
      };

      if (reminderSelected) {
        settings.reminder = reminderSelected;
      }

      updateSettings(settings);

      setLoading(false);
      notify("tr", "success", "Configurações atualizadas com sucesso!");
    } catch (err) {
      setLoading(false);
      notify("tr", "danger", "Erro ao atualizar configurações.");
    }
  }

  const dayDefault = user.settings[0] ? user.settings[0].reminder : null;
  let arrayDefault = [];
  if (dayDefault) {
    arrayDefault.push({
      value: dayDefault,
      label: dayDefault > 1 ? `${dayDefault} dias` : `${dayDefault} dia`,
    });
  } else {
    arrayDefault.push({
      value: null,
      label: "Nenhum",
    });
  }

  const [reminders, setReminders] = useState([]);
  const [reminderSelected, setReminderSelected] = useState(null);
  const [reminderToAdd, setReminderToAdd] = useState(null);
  const [reminderDefault, setReminderDefault] = useState(arrayDefault[0]);

  const getReminders = useCallback(async () => {
    try {
      const response = await api.get("/store/reminders", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setReminders(response.data.all);
      setReminderDefault(response.data.default[0]);
      console.log(response.data.default[0]);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token]);

  const addReminders = useCallback(
    async (days) => {
      try {
        const data = { days };

        const response = await api.post("/store/reminders", data, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setReminders(response.data);
        document.getElementById("reminderInput").value = "";
        notify("tr", "success", "Adicionado com sucesso!");
      } catch (err) {
        notify("tr", "danger", "Erro ao adicionar.");
      }
    },
    [token]
  );

  const getSmsSpent = async () => {
    try {
      const response = await api.get("/store/sms/spent", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSpent(response.data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  };

  const checkWppStatus = async () => {
    const status = await apiUzapi.post(`/getConnectionStatus`, {
      session: settings?.takeat_name,
    });

    const connected = status.data.state === "CONNECTED";

    setWppConnected(connected);
    localStorage.setItem(
      "@gddashboard:wppConnected",
      JSON.stringify(connected)
    );
  };

  useEffect(() => {
    getReminders();
    getSmsSpent();
    if (settings?.use_wpp) {
      checkWppStatus();
    }
    if (user.id) {
      getUser();
    }
  }, []);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Form
          ref={formRef}
          initialData={settings}
          onSubmit={handleSubmitSettings}
        >
          <Row>
            <Col>
              <h2>
                <i className="nc-icon nc-settings" /> Configurações
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card>
                <CardHeader>
                  <h5 className="title">Modelo de Vantagens</h5>
                </CardHeader>
                <CardBody>
                  <p>
                    Escolha a porcentagem de cashback que seu cliente irá ganhar
                    a partir do valor gasto em seu estabelecimento.
                  </p>
                  <Row>
                    {typeSelected === "cashback" ? (
                      <Col md="6">
                        <FormGroup>
                          <label>Taxa de Cashback (%)</label>
                          <Input name="percent" type="text" placeholder="%" />
                        </FormGroup>
                      </Col>
                    ) : (
                      <>
                        <Col md="6">
                          <FormGroup>
                            <label>
                              A Cada quantos reais completa um ponto:
                            </label>
                            <Input name="points" type="text" />
                          </FormGroup>
                        </Col>
                      </>
                    )}
                    <Col md="6">
                      <FormGroup>
                        <label>Resgate Mínimo</label>
                        <Input name="minimo" type="text" placeholder="%" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>Validade (em dias): </label>
                        <Input name="expires" type="number" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="title">Lembrete</h5>
                  <p>
                    Cadastre os dias de lembrete que estarão disponíveis na
                    operação.
                  </p>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      {reminders.map(
                        (reminder) =>
                          reminder.value !== null && (
                            <div
                              style={{
                                width: "100%",
                                textAlign: "center",
                                paddingBottom: 15,
                              }}
                            >
                              {reminder.label}
                            </div>
                          )
                      )}
                    </Col>
                    <Col md="8">
                      <Row>
                        <Col md="8">
                          <label>Adicionar:</label>
                          <Input
                            name="reminderInput"
                            id="reminderInput"
                            type="number"
                            onChange={(e) => setReminderToAdd(e.target.value)}
                          />
                        </Col>
                        <Col md="4">
                          <Button
                            style={{ background: "#FE5448", marginTop: 30 }}
                            onClick={() => addReminders(reminderToAdd)}
                          >
                            <i className="nc-icon nc-simple-add" />
                          </Button>
                        </Col>
                      </Row>

                      <Select
                        label="Padrão:"
                        name="default"
                        options={reminders}
                        defaultValue={reminderDefault}
                        onChange={(e) => setReminderSelected(e.value)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="title">
                    {/* <Switch onColor="success" offColor="success" />{" "} */}
                    Limites de SMS
                  </h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <FormGroup style={{ textAlign: "center" }}>
                        <h6>Registro </h6>
                        <p>
                          {spent.register}/{settings.limit_register}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup style={{ textAlign: "center" }}>
                        <h6>Lembrete </h6>
                        <p>
                          {spent.reminder}/{settings.limit_reminder}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup style={{ textAlign: "center" }}>
                        <h6>Validade </h6>
                        <p>
                          {spent.expires}/{settings.limit_expires}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardHeader>
                  <h5 className="title">
                    Aniversários{" "}
                    <Switch
                      style={{ marginBottom: 0 }}
                      onColor="success"
                      offColor="success"
                      value={hasBirthday}
                      onChange={(e) => {
                        setHasBirthday(e.state.value);
                        if (!e.state.value) {
                          setBenefitType(null);
                          setBenefitValue(null);
                        }
                      }}
                    />
                  </h5>
                  <p>
                    Escolha o benefício que seu cliente irá ganhar em seu
                    aniversário para gastar em seu estabelecimento. Uma SMS será
                    enviada no dia anterior ao aniversário de todos os seus
                    clientes.
                  </p>
                </CardHeader>
                {hasBirthday && (
                  <CardBody>
                    <RadioGroup>
                      <div onClick={() => setBenefitType("discount")}>
                        <Radio
                          checked={benefitType === "discount"}
                          value={"discount"}
                        />
                        <span>Desconto</span>
                      </div>
                      <div onClick={() => setBenefitType("product")}>
                        <Radio
                          checked={benefitType === "product"}
                          value={"product"}
                        />
                        <span>Produto</span>
                      </div>
                    </RadioGroup>
                    <div
                      style={{ display: "flex", gap: 10, alignItems: "center" }}
                    >
                      <Input
                        type={benefitType === "discount" ? "number" : "text"}
                        style={{ width: "200px" }}
                        name="birthday_benefit"
                        placeholder={
                          benefitType === "discount"
                            ? "xx%"
                            : "Escreva um benefício"
                        }
                        value={benefitValue}
                        onChange={(e) => setBenefitValue(e.target.value)}
                      />
                    </div>
                    <div>
                      <p style={{ margin: "15px 0 5px" }}>
                        Prévia do SMS a ser enviado:{" "}
                      </p>
                      <Preview>
                        Parabéns, {`<nome do cliente>`}! Venha comemorar seu
                        aniversário no {user.name} e ganhe{" "}
                        {benefitType === "discount"
                          ? `${
                              benefitValue || "<escolha um valor>"
                            }% de desconto`
                          : `${benefitValue || "<escolha um produto>"}`}{" "}
                        por nossa conta! Esperamos por você!
                      </Preview>
                    </div>
                  </CardBody>
                )}
              </Card>
              <Card>
                <CardHeader>
                  <h5> Observações</h5>
                  <p>
                    Escreva observações das regras de seu estabelecimento que
                    seus clientes terão acesso ao se cadastrar.
                  </p>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <Input
                        name="obs"
                        type="text"
                        style={{ width: "100%", height: "50px" }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {user.id === 2 && (
                <Card>
                  <CardHeader>
                    <h5 className="title" style={{ margin: 0 }}>
                      Status do Whatsapp
                    </h5>
                  </CardHeader>
                  <CardBody>
                    {!settings?.use_wpp ? (
                      <p>
                        Entre em contato com nossa equipe comercial para ativar
                        o uso do whatsapp em seu estabelecimento!
                      </p>
                    ) : wppConnected ? (
                      <WhatsppDiv>
                        <p style={{ color: "green" }}>Conectado!</p>
                      </WhatsppDiv>
                    ) : (
                      <WhatsppDiv>
                        <p style={{ color: "red" }}>Desconectado</p>
                      </WhatsppDiv>
                    )}
                  </CardBody>
                </Card>
              )}
              <Button color="success" type="submit" style={{ float: "right" }}>
                {loading ? (
                  <span>
                    <Spinner size="sm" color="white" />
                  </span>
                ) : (
                  <span>Atualizar</span>
                )}
              </Button>
            </Col>
          </Row>
          {/* <Row>
          <Col md="6">
              <Card>
                <CardHeader>
                  <h5 className="title">
                    <Switch onColor="success" offColor="success" />{" "}
                    Produtos
                  </h5>
                  <p>O cadastro de produtos serve para uma melhor experiência para entender cada um de seus clientes. Ao ativar, você consegue adicionar produtos e relacionar as compras de seus clientes.</p>
                </CardHeader>
              </Card>
            </Col>
          </Row> */}
          {/* <Row style={{ paddingTop: 15 }}>
            <Col md="3" className="ml-auto">
              <Button color="success" type="submit" style={{float: 'right'}}>
              {loading ? <span><Spinner size="sm" color="white" /></span> : <span>Atualizar</span>}
              </Button>
            </Col>
          </Row> */}
        </Form>
      </div>
    </>
  );
}

export default Settings;
