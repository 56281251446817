import React, { useRef, useEffect } from "react";
import ReactSelect from "react-select";
import { useField } from "@unform/core";

import { SelectContainer } from "./styles";

function Select({ name, label, label_fantasy, width, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: "state.value",
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }

          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }

        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <SelectContainer style={{ width: width && width }}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />{" "}
      {error && <span>{error}</span>}
    </SelectContainer>
  );
}

export default Select;
