import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  label {
    font-weight: bold;
  }

  input {
    width: 100%;
    height: 40px;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;

    &:disabled {
      background: #e3e3e3;
    }
  }
  span {
    font-weight: bold;
    color: blue;
  }
`;
