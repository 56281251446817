import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import Login from "../views/pages/Login";
import Dashboard from "../views/Dashboard.jsx";
import Footer from "components/Footer/Footer.jsx";
import { FromTakeat } from "views/pages/FromTakeat";

var ps;

class Pages extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <>
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className="full-page section-image">
            <Switch>
              <Route path="/" exact component={Login} />
              <Route path="/redirect/:token" exact component={FromTakeat} />
              <Route path="/dashboard" exact component={Dashboard} isPrivate />
            </Switch>
            <Footer fluid />
          </div>
        </div>
      </>
    );
  }
}

export default Pages;
