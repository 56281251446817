import React, { lazy, Suspense } from "react";
import { Card, CardBody, Spinner, Table } from "reactstrap";
const ClientRowTable = lazy(() => import("../ClientRowTable"));

function ClientsTable({ clients, cashbackModel, loading }) {
  return (
    <>
      <Card style={{ overflowY: "scroll", maxHeight: "500px" }}>
        <CardBody>
          <Table className="table-shopping">
            <thead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#ffffff",
                zIndex: 1,
              }}
            >
              <tr style={{ fontSize: 13 }}>
                <th>Nome</th>
                <th className="text-right">Número </th>
                <th className="text-right">Cashback Disponível</th>
                <th className="text-center">Compras</th>
                <th className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              <Suspense fallback={<div>Loading...</div>}>
                {loading ? (
                  <tr>
                    <td colSpan={5} style={{ textAlign: "center" }}>
                      <Spinner />
                    </td>
                  </tr>
                ) : (
                  clients.map((client) => (
                    <ClientRowTable
                      client={client}
                      cashbackModel={cashbackModel}
                    />
                  ))
                )}
              </Suspense>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
}

export default ClientsTable;
