import React, { useState } from "react";
import { Button, Modal, ModalBody, Row, Col } from "reactstrap";
import { AiOutlineWhatsApp } from "react-icons/ai";

import { useAuth } from "../../../context/AuthContext";
import formatCompleteDate from "../../../services/formatCompleteDate";
import formatPhoneString from "../../../services/formatPhoneString";
import ClientModal from "../ClientModal";
import NotClientModal from "../NotClientModal";
import { RatingButton } from "./styles";

function TableHistoryPurchase({ purchase, clients, notClients }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalPurchase, setModalPurchase] = useState(false);
  const togglePurchase = () => setModalPurchase(!modalPurchase);

  const [modalRating, setModalRating] = useState(false);
  const toggleRating = () => setModalRating(!modalRating);

  const { user } = useAuth();
  const settings = user.settings[0];

  let products = "";
  if (purchase.products.length > 0) {
    purchase.products.forEach((product) => {
      if (products === "") {
        products = product.product ? product.product.name : "";
      } else {
        products =
          products + `, ${product.product ? product.product.name : ""}`;
      }
    });
  }

  let ratings = "";
  if (purchase.rated === "done") {
    if (
      purchase.rating.length > 0 &&
      purchase.rating[0].rating_adjectives.length > 0
    ) {
      purchase.rating[0].rating_adjectives.forEach((rating) => {
        if (ratings === "") {
          ratings = rating.adjective;
        } else {
          ratings = ratings + `, ${rating.adjective}`;
        }
      });
    }
  }

  function titleize(text) {
    var words = text.toLowerCase().split(" ");
    for (var a = 0; a < words.length; a++) {
      var w = words[a];
      if (w[0]) {
        words[a] = w[0].toUpperCase() + w.slice(1);
      }
    }
    return words.join(" ");
  }

  return (
    <>
      <tr style={{ fontSize: 13 }}>
        <td style={{ color: "#6C606A" }}>
          <strong>
            <a
              href={`https://api.whatsapp.com/send?phone=55${purchase.phone
                .replace("(", "")
                .replace(")", "")}&text=Olá!`}
              target="_blank"
            >
              <AiOutlineWhatsApp />
              {formatPhoneString(purchase.phone)}
            </a>
          </strong>
        </td>
        <td>
          {purchase.client ? (
            <strong
              style={{ color: "#51bcda" }}
              title={
                purchase.client &&
                purchase.client.name &&
                titleize(purchase.client.name)
              }
            >
              {purchase.client &&
                titleize(purchase.client.name.substring(0, 16))}
            </strong>
          ) : (
            <strong style={{ color: "red" }}>Sem Cadastro</strong>
          )}
        </td>
        <td className="text-center" style={{ color: "green" }}>
          R$
          {parseFloat(purchase.total).toLocaleString("pt-br", {
            minimumFractionDigits: 2,
          })}
        </td>
        <td className="text-right">
          {purchase.cashback
            ? `R$${parseFloat(purchase.cashback).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              })}`
            : purchase.points
            ? `${purchase.points} pontos`
            : "-"}
        </td>
        <td className="text-right" style={{ color: "red" }}>
          {purchase.rescue
            ? purchase.rescue.cashback
              ? `R$${parseFloat(purchase.rescue.cashback).toLocaleString(
                  "pt-br",
                  { minimumFractionDigits: 2 }
                )}`
              : `${purchase.rescue.points} pontos`
            : "-"}
        </td>
        <td className="text-right" style={{ color: "#CC8F00" }}>
          {purchase.rated === "done" && purchase.rating.length > 0 ? (
            <RatingButton
              onClick={() => toggleRating()}
              number={purchase.rating[0].stars}
            >
              {purchase.rating[0].stars}
            </RatingButton>
          ) : (
            "-"
          )}
        </td>
        <td className="text-right">{formatCompleteDate(purchase.createdAt)}</td>
        <td className="text-right">
          <Button
            className="btn-icon"
            color="success"
            id="tooltip264453216"
            size="sm"
            type="button"
            onClick={togglePurchase}
          >
            <i className="nc-icon nc-cart-simple" />
          </Button>
          {purchase.client ? (
            <Button
              className="btn-icon"
              color="info"
              id="tooltip264453216"
              size="sm"
              type="button"
              onClick={toggle}
            >
              <i className="fa fa-user" />
            </Button>
          ) : (
            <Button
              className="btn-icon"
              color="danger"
              id="tooltip264453216"
              size="sm"
              type="button"
              onClick={toggle}
            >
              <i className="fa fa-user" />
            </Button>
          )}
        </td>
      </tr>
      {purchase.client ? (
        <ClientModal purchase={purchase} modal={modal} toggle={toggle} />
      ) : (
        <NotClientModal purchase={purchase} modal={modal} toggle={toggle} />
      )}

      <Modal isOpen={modalPurchase} toggle={togglePurchase}>
        <ModalBody>
          <Row>
            <Col>
              <h5>Detalhes da Compra</h5>
            </Col>
          </Row>
          <Row>
            <Col md="7">
              {purchase.client ? (
                <>
                  <strong>Cliente: </strong> {titleize(purchase.client.name)}
                </>
              ) : (
                <>
                  <strong>Telefone: </strong>{" "}
                  {formatPhoneString(purchase.phone)}
                </>
              )}
            </Col>
            <Col md="5">
              <strong>Data: </strong> {formatCompleteDate(purchase.createdAt)}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="7">
              <strong>Valor da Compra: </strong> R$
              {parseFloat(purchase.total).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              })}
            </Col>
            <Col md="5">
              {settings?.model === "cashback" ? (
                <>
                  <strong style={{ color: "green" }}>
                    Cashback: R${purchase.cashback}
                  </strong>{" "}
                </>
              ) : (
                <>
                  <strong style={{ color: "green" }}>
                    Pontos: {purchase.points} ponto(s)
                  </strong>
                </>
              )}
            </Col>
          </Row>
          {user.plan === "premium" && products.length > 0 && (
            <Row style={{ paddingTop: 15 }}>
              <Col md="7">
                <strong>Produtos: </strong>
                {products}
              </Col>
            </Row>
          )}
          {purchase.rescue && (
            <Row style={{ paddingTop: 15 }}>
              <Col md="12">
                <strong>Resgate: </strong>{" "}
                {purchase.rescue.promotion
                  ? `${purchase.rescue.promotion.name} (${purchase.rescue.promotion.points} pontos)`
                  : `R$${parseFloat(purchase.rescue.cashback).toLocaleString(
                      "pt-br",
                      { minimumFractionDigits: 2 }
                    )}`}
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>

      <Modal isOpen={modalRating} toggle={toggleRating}>
        {purchase.rated === "done" && (
          <ModalBody>
            <Row>
              <Col>
                <h5>Detalhes da Avaliação do Cliente</h5>
              </Col>
            </Row>
            <Row style={{ paddingTop: 15 }}>
              <Col md="7">
                <strong>Avaliação NPS de 0 a 10: </strong>
                <RatingButton number={purchase.rating[0]?.stars}>
                  {purchase.rating[0]?.stars}
                </RatingButton>
              </Col>
            </Row>
            <Row style={{ paddingTop: 15 }}>
              <Col md="12">
                <p>
                  <strong>
                    {purchase.rating[0]?.stars < 8
                      ? "Ao ser perguntado o que poderia melhorar em seu estabelecimento, o cliente respondeu:"
                      : "Ao ser perguntado o que mais gostou em seu estabelecimento, o cliente respondeu:"}
                  </strong>{" "}
                  {ratings}
                </p>
              </Col>
            </Row>
            <Row style={{ paddingTop: 15 }}>
              <Col md="12"></Col>
            </Row>
          </ModalBody>
        )}
      </Modal>
    </>
  );
}

export default TableHistoryPurchase;
