import React, { useEffect, useState } from "react";
import { differenceInYears, format } from "date-fns";

// reactstrap components
import {
  Card,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Spinner,
} from "reactstrap";
import { AiOutlineWhatsApp } from "react-icons/ai";

import formatPhoneString from "../../../services/formatPhoneString";
import ClientModal from "../ClientModal";
import { useAuth } from "context/AuthContext";

function BirthdaysTable({ clients, birthdaysLoading }) {
  const [detailsModal, setDetailsModal] = useState(false);
  const [currentClient, setCurrentClient] = useState({});

  const toggleDetails = () => {
    setDetailsModal(!detailsModal);
  };
  const { user } = useAuth();

  return (
    <>
      <Card style={{ overflowY: "scroll", maxHeight: "500px" }}>
        <CardBody>
          <Table className="table-shopping">
            <thead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#ffffff",
                zIndex: 1,
              }}
            >
              <tr style={{ fontSize: 13 }}>
                <th>Nome</th>
                <th style={{ textAlign: "center" }}>Idade</th>
                <th style={{ textAlign: "center" }}>Aniversário</th>
                <th style={{ textAlign: "center" }}>Telefone</th>
                <th style={{ textAlign: "center" }}>Compras</th>
                <th style={{ textAlign: "center" }}>Gastos</th>
                <th style={{ textAlign: "right" }}>Produtos preferidos</th>
              </tr>
            </thead>
            {birthdaysLoading ? (
              <td colSpan={7} style={{ textAlign: "center" }}>
                <Spinner style={{ margin: "15px 0 0 0 " }} />
              </td>
            ) : (
              <tbody>
                {clients.map((c, i) => {
                  return (
                    <tr key={i}>
                      <td className="td-name text-info">
                        <strong>{c.name}</strong>
                      </td>
                      <td style={{ textAlign: "center", fontSize: 21 }}>
                        {differenceInYears(new Date(), new Date(c.birthday))}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 21 }}>
                        {format(new Date(c.birthday), "dd/MM/yyyy")}
                      </td>
                      <td className="td-number" style={{ textAlign: "center" }}>
                        <a
                          href={`https://api.whatsapp.com/send?phone=55${c.phone
                            .replace("(", "")
                            .replace(")", "")}`}
                          target="_blank"
                        >
                          <AiOutlineWhatsApp />
                          {formatPhoneString(c.phone)}
                        </a>
                      </td>
                      <td style={{ textAlign: "center", fontSize: 21 }}>
                        {c.purchases.length > 0 ? c.purchases.length : "-"}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 21 }}>
                        R$
                        {c.purchases.reduce(
                          (acc, cur) => acc + parseFloat(cur.total),
                          0
                        ) > 0
                          ? c.purchases
                              .reduce(
                                (acc, cur) => acc + parseFloat(cur.total),
                                0
                              )
                              .toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                              })
                          : " -"}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <Button
                          color="info"
                          type="button"
                          onClick={() => {
                            setCurrentClient(c);
                            toggleDetails();
                          }}
                        >
                          <i className="nc-icon nc-minimal-right" />
                        </Button>{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
        </CardBody>
      </Card>
      <ClientModal
        purchase={currentClient}
        modal={detailsModal}
        toggle={toggleDetails}
        cashbackModel={user.settings[0]?.model}
      />
    </>
  );
}

export default BirthdaysTable;
