import React, {useState, useCallback} from "react";
import { Form } from "@unform/web";

// reactstrap components
import {
  Button,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  Label
} from "reactstrap";

import Input from "../Input";
import AvatarInput from "components/AvatarInput";

import formatPhoneString from '../../../services/formatPhoneString';
import formatCompleteDate from '../../../services/formatCompleteDate';
import api from '../../../services/api';

import {BiTrashAlt, BiEdit} from 'react-icons/bi';

function PromotionRowTable({promotion, handleDelete, getPromotions, notify}) {
    
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  
  const token = localStorage.getItem("@clube:token");

  const editPromotion = useCallback(async (data) => {
    try {
      if(data.name === ''){
        notify("tr", "danger", "Preencha o nome da oferta.");
      }else if(data.points === ''){
        notify("tr", "danger", "Preencha os pontos da oferta.");
      }else{
        data.avatar_id = parseInt(data.avatar);
        await api.put(`/store/promotions/${promotion.id}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setModalEdit(false);
        getPromotions();
        notify("tr", "success", "Oferta atualizada com sucesso.");
      }
    } catch (err) {
      notify("tr", "danger", "Erro ao editar oferta.");
    }
  }, [token]);

    return (
      <>
        <tr style={{fontSize: 12}}>
            <td>
                <div className="img-container">
                <img
                    alt="..."
                    src={promotion.avatar ? promotion.avatar.url : null}
                    style={{width: 100, height: 100, borderRadius: '50%'}}
                />
                </div>
            </td>
            <td className="td-name">
                <h5>{promotion.name}</h5>
            </td>
            <td className="td-number">
                {promotion.points} ponto(s)
            </td>
            <td className="td-number">
                {promotion.rescues ? promotion.rescues.length : '0'} resgate(s)
            </td>
            <td />

            <td className="text-right">
                <Button
                color="info"
                id="tooltip264453216"
                type="button"
                onClick={toggle}
                >
                <i className="fa fa-plus" /> Detalhes
                </Button>{" "}
            </td>
            <td className="td-actions">
              <div>
                <div>
                  <Button
                  className="btn-neutral"
                  color="default"
                  data-placement="left"
                  id={`delete${promotion.id}`}
                  title=""
                  type="button"
                  onClick={() => handleDelete(promotion.id)}
                  >
                  <BiTrashAlt size={18} color="#dc3545"/>
                  </Button>
                  <UncontrolledTooltip
                  delay={0}
                  placement="left"
                  target={`delete${promotion.id}`}
                  >
                  Excluir Promoção
                  </UncontrolledTooltip>
                </div>
                <div>
                  <Button
                  className="btn-neutral"
                  color="default"
                  data-placement="left"
                  id={`edit${promotion.id}`}
                  title=""
                  type="button"
                  onClick={() => {toggleEdit()}}
                  >
                  <BiEdit size={18} color="#08839B"/>
                  </Button>
                  <UncontrolledTooltip
                  delay={0}
                  placement="left"
                  target={`edit${promotion.id}`}
                  >
                  Editar Promoção
                  </UncontrolledTooltip>
                </div>
              </div>
            </td>
        </tr>
              
        <Modal isOpen={modal} toggle={toggle} size='lg'>
            <ModalBody>
                <Row>
                    <Col md="9">
                    <h3>Clientes</h3>
                    </Col>
                    {/* <Col md="3" className="ml-auto" >
                    <Button color="success">
                        Enviar Promoção
                    </Button>
                    </Col>  */}
                </Row>
              
              <Table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Telefone</th>
                    <th className="text-right">Resgatou em</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    promotion.rescues && promotion.rescues.map(rescue => 
                      <tr>
                        <td>{rescue.client ? rescue.client.name : "Cliente não cadastrado"}</td>
                        <td>{formatPhoneString(rescue.phone)}</td>
                        <td className="text-right">{formatCompleteDate(rescue.createdAt)}</td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Modal isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader>
              Editar Promoção
            </ModalHeader>
            <ModalBody>
              <Form initialData={promotion} onSubmit={editPromotion}>
                <Row>
                  <Col md="4" sm="4">
                    <AvatarInput name="avatar" />
                  </Col>
                  <Col>
                    <Label>Promoção: </Label>
                    <Input name="name" type="text" id="name"></Input>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Pontos Necessários para Resgate: </Label>
                    <Input name="points" type="number" id="points"></Input>
                  </Col>
                </Row>
                <Row md="12">
                  <Col md="12">
                    <Button style={{float: 'right', background: 'green'}} type="submit">Salvar</Button> 
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </>
    );
}

export default PromotionRowTable;
