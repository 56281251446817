import React from "react";
import Rating from "@material-ui/lab/Rating";

import { Ratings, HeaderLegend } from "./styles";
import { Row, Col } from "reactstrap";
import { Loader } from "components/Loader";

function RatingComponent({ rate, ratingLoading }) {
  return (
    <Ratings>
      <Row style={{ paddingTop: 10 }}>
        <Col>
          <HeaderLegend>Avaliações</HeaderLegend>
        </Col>
      </Row>

      {ratingLoading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <Row style={{ marginBottom: -20 }}>
            <Col md="6" style={{ textAlign: "right" }}>
              <h1 style={{ color: "#ffb400" }}>
                {rate.ratings_average || "0.00"}
              </h1>
            </Col>
            <Col style={{ textAlign: "left", marginTop: 15 }}>
              <Rating
                name="half-rating-read"
                value={rate.ratings_average || 0}
                precision={0.01}
                readOnly
              />
            </Col>
          </Row>
          <p>{rate.ratings_count || 0} avaliações</p>
          <Row style={{ marginLeft: 7, marginBottom: 10 }}>
            <Col md="6" style={{ color: "#28a745" }}>
              <h6>Avaliações Positivas</h6>
              {rate.good_reviews &&
                rate.good_reviews.map((review) => (
                  <Row style={{ marginTop: 10 }}>
                    <Col style={{ textAlign: "left" }} md="4">
                      {review.adjective}
                    </Col>
                    <Col style={{ textAlign: "right" }} md="7">
                      {review.count}
                    </Col>
                  </Row>
                ))}
            </Col>
            <Col md="6" style={{ color: "#dc3545" }}>
              <h6>Avaliações Negativas</h6>

              {rate.bad_reviews &&
                rate.bad_reviews.map((review) => (
                  <Row style={{ marginTop: 10 }}>
                    <Col style={{ textAlign: "left" }} md="4">
                      {review.adjective}
                    </Col>
                    <Col style={{ textAlign: "right" }} md="7">
                      {review.count}
                    </Col>
                  </Row>
                ))}
            </Col>
          </Row>
        </>
      )}
    </Ratings>
  );
}

export default RatingComponent;
