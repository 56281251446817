import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { format, startOfDay, endOfDay, subHours, startOfWeek, startOfMonth, addHours} from 'date-fns';
import { Form } from "@unform/web";

import {
  // Badge,
  Button,
  Label,
  // FormGroup,
  // Input,
  // Table,
  Row,
  Col,
  Collapse,
  Modal,
  ModalBody,
  Table,
  ModalHeader
  // UncontrolledTooltip,
} from "reactstrap";

import api from "../../../services/api";
import NotificationAlert from "react-notification-alert";
import Input from "../../components/Input";
import ProductsTable from "../../components/ProductsTable";
import CardDash from "components/Cards/CardDash";

function Products(){
  const formRef = useRef(null);
  const notificationAlert = useRef();

  const token = localStorage.getItem("@clube:token");

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const [products, setProducts] = useState([]);

  const getProducts = useCallback(async () => {
    try {
      const response = await api.get("/store/products", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProducts(response.data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token]);

  const handleNewProduct = useCallback(async (data) => {
    try {
      const response = await api.post("/store/products", data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      notify("tr", "success", "Produto cadastrado com sucesso!");
      getProducts();
      setModal(false);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token]);

  const handleDelete = useCallback(async (id) => {
    if(window.confirm('Deseja excluir essa segmentação?')){
      try {
        const response = await api.delete(`/store/products/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        getProducts();
        notify("tr", "success", "Segmentação removida com sucesso!");
      } catch (err) {
        notify("tr", "danger", "Erro ao remover segmentação.");
      }
    }
  }, [token]);

  useEffect(() => {
    getProducts();
  }, []);


    return (
      <>
      <NotificationAlert ref={notificationAlert} />
        <div className="content">
          <Row>
            <Col md="9">
            <h2><i className="nc-icon nc-bag-16" />  Segmentações</h2>
            </Col>
            <Col md="3" className="ml-auto" >
              <Button color="danger" onClick={toggle} style={{float: 'right'}}>
              <i className="nc-icon nc-simple-add"/>Nova Segmentação
              </Button>
            </Col>
          </Row>

          <Row md="12">
            <Col md="12">           
             <ProductsTable products={products} getProducts={getProducts} 
             notify={notify} handleDelete={handleDelete}/>
            </Col>
          </Row>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>
              Cadastrar Segmentação
            </ModalHeader>
            <ModalBody>
              <Form  onSubmit={handleNewProduct}>
                <Row>
                  <Col>
                    <Label>Nome: </Label>
                    <Input name="name" type="text"></Input>
                  </Col>
                </Row>
                <Row md="12">
                  <Col md="12">
                  <Button style={{float: 'right', background: 'green'}} type="submit">Cadastrar</Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
}

export default Products;
