import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";
import ProductRowTable from "../ProductRowTable";

function ProductsTable({products, notify, getProducts, handleDelete}) {
    
    return (
      <>
        <Card>
            <CardBody>
                <Table className="table-shopping" responsive>
                <thead>
                    <tr style={{fontSize: 13}}>
                    <th className="text-center" />
                    <th>Segmentação</th>
                    <th />
                    <th className="text-right">Clientes</th>
                    <th className="text-right">Compras</th>
                    <th />
                    <th className="text-right">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        products.map(product => <ProductRowTable product={product}
                            notify={notify} getProducts={getProducts} handleDelete={handleDelete}/>)
                    }
                </tbody>
                </Table>
            </CardBody>
        </Card>
        </>
    );
}

export default ProductsTable;
