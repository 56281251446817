import styled, { css } from 'styled-components';
import { shade } from 'polished';


export const RatingButton = styled.div`
  background: none;
  color: black;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 50%;
  width: 25px;
  heigth: 25px;
  padding: 3px;
  text-align: center;
  margin-right: 8px;
  float: right;

  ${props => props.number === 0 &&
    css`
      background: #014331;
    `
  };

  ${props => props.number === 1 &&
    css`
      background: #01543D;
    `
  };

  ${props => props.number === 2 &&
    css`
      background: #026549;
    `
  };

  ${props => props.number === 3 &&
    css`
      background: #027655;
    `
  };

  ${props => props.number === 4 &&
    css`
      background: #028761;
    `
  };

  ${props => props.number === 5 &&
    css`
      background: #02986D;
    `
  };

  ${props => props.number === 6 &&
    css`
      background: #03A97A;
    `
  };

  ${props => props.number === 7 &&
    css`
      background: #03BA86;
    `
  };

  ${props => props.number === 8 &&
    css`
      background: #03CA92;
    `
  };

  ${props => props.number === 9 &&
    css`
      background: #03DB9E;
    `
  };

  ${props => props.number === 10 &&
    css`
      background: #04ECAC;
    `
  };
`;
