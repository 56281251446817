import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";
import PromotionRowTable from "../PromotionRowTable";

function PromotionsTable({promotions, handleDelete, getPromotions, notify}) {
    
    return (
      <>
        <Card>
            <CardBody>
                <Table className="table-shopping" responsive>
                <thead>
                    <tr style={{fontSize: 13}}>
                    <th className="text-center" />
                    <th>Promoção</th>
                    <th className="text-right">Pontos Necessários</th>
                    <th className="text-right">Resgates</th>
                    <th />
                    <th className="text-right">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        promotions.map(promotion => <PromotionRowTable 
                            promotion={promotion} handleDelete={handleDelete}
                            getPromotions={getPromotions} notify={notify}/>)
                    }
                </tbody>
                </Table>
            </CardBody>
        </Card>
        </>
    );
}

export default PromotionsTable;
