import { io } from "socket.io-client";

export default class WebsocketManager {
  constructor(token) {
    this.token = token;
    this.ws_url = process.env.REACT_APP_API_URL;
    this.socket = null;

    this.socketConnect();
  }

  socketConnect() {
    if (this.token) {
      this.socket = io(this.ws_url, {
        reconnectionDelayMax: 10000,
        transports: ["websocket"],
      });

      this.socket.on("event", (message) => {
        console.log("### event", typeof message);
        this.onMessage(JSON.parse(message));
      });
    } else {
      console.warn("Could not init websocket manager.");
    }
  }
}
