import React, { useRef } from "react";
import Input from "../components/Input";
import { Form } from "@unform/web";
import { useAuth } from "../../context/AuthContext";
import NotificationAlert from "react-notification-alert";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Row,
  Col,
} from "reactstrap";

import ShopAvatarInput from "../components/ShopAvatarInput";
import api from "../../services/api";
import logoTakeat from "assets/img/logotakeat.png";

function UserProfile() {
  const notificationAlert = useRef(null);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const formRef = useRef(null);
  const { user, updateProfile } = useAuth();

  const initialData = {
    username: user.username,
    fantasy_name: user.fantasy_name,
    email: user.email,
    phone: user.phone,

    avatar: {
      id: user.avatar ? user.avatar.id : null,
      url: user.avatar ? user.avatar.url : null,
    },
  };

  async function handleSubmitProfile(data) {
    const response = await updateProfile(data);
    response
      ? notify("tr", "success", "Perfil atualizado com sucesso!")
      : notify("tr", "danger", "Falha ao atualizar perfil.");
  }

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Form
          ref={formRef}
          initialData={initialData}
          onSubmit={handleSubmitProfile}
        >
          <Row>
            <Col md="4">
              <Card className="card-user">
                <div
                  className="image"
                  style={{ placeContent: "center", padding: 30 }}
                >
                  <img src={logoTakeat} alt="react-logo" />
                </div>
                <CardBody>
                  <div style={{ textAlign: "center" }}>
                    <h5 className="title">{user.fantasy_name}</h5>
                    <p style={{ color: "grey" }}>
                      Perfil de administração da Takeat.
                    </p>
                    <p style={{ color: "grey" }}>Desde 2020.</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Editar Perfil</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="pr-1" md="7">
                      <label>Nome</label>
                      <Input
                        name="fantasy_name"
                        placeholder="Nome"
                        type="text"
                      />
                    </Col>
                    <Col className="pl-1" md="5">
                      <FormGroup>
                        <label>Username</label>
                        <Input
                          name="username"
                          disabled
                          placeholder="Username"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8" className="pr-1">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">E-mail</label>
                        <Input
                          name="email"
                          placeholder="mestrealvaro@gmail.com"
                          type="email"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" className="pl-1">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Telefone</label>
                        <Input
                          name="phone"
                          placeholder="(xx) xxxxx-xxxx"
                          type="phone"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Endereço</label>
                        <Input
                          defaultValue="Av. João Palácio, 300 - Eurico Salles"
                          placeholder="Endereço"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                  {/* <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Cidade</label>
                        <Input
                          defaultValue="Serra"
                          placeholder="Cidade"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="4">
                      <FormGroup>
                        <label>Estado</label>
                        <Input
                          defaultValue="ES"
                          placeholder="Estado"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>CEP</label>
                        <Input placeholder="29160-169" type="number" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Sobre o Shopping</label>
                        <Input
                          className="textarea"
                          type="textarea"
                          cols="80"
                          rows="4"
                          defaultValue="Com 46 anos de mercado, a Sá Cavalcante é formada por quatro unidades estratégicas de negócio: Shopping Centers, Incorporação Imobiliária, Franquias e Comunicação. Atua também no desenvolvimento, construção e administração dos empreendimentos, com sinergia total entre as atividades."
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <Row style={{ paddingTop: 15 }}>
                    <Col md="4" className="ml-auto">
                      <Button color="success" type="submit">
                        Atualizar Perfil
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default UserProfile;
