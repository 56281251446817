import React, { useState } from "react";
import { differenceInDays } from "date-fns";
import { Button } from "reactstrap";
import formatPhoneString from "../../../services/formatPhoneString";
import NotClientModal from "../NotClientModal";
import { AiOutlineWhatsApp } from "react-icons/ai";

function NotClientRowTable({ client, cashbackModel }) {
  const pointsModel = cashbackModel ? false : true;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <tr style={{ fontSize: 12 }}>
        <td className="td-number text-left">
          <a
            href={`https://api.whatsapp.com/send?phone=55${client.phone
              .replace("(", "")
              .replace(")", "")}&text=${`Olá! Você tem ${
              pointsModel
                ? `${client.points} ponto(s)`
                : `R$${parseFloat(client.cashback).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}`
            } de cashback para usar em nosso estabelecimento!`}`}
            target="_blank"
          >
            <AiOutlineWhatsApp />
            {formatPhoneString(client.phone)}
          </a>
        </td>

        <td className="td-number">
          <strong style={{ color: "green" }}>
            {cashbackModel
              ? `R$${client.cashback || 0}`
              : `${client.points || 0} ponto(s)`}
          </strong>
        </td>
        <td className="td-number">
          {client.purchases.length > 0 ? client.purchases.length : "-"}
        </td>
        <td className="td-number">
          {differenceInDays(new Date(), new Date(client.lastPurchase)) > 0
            ? `há ${differenceInDays(
                new Date(),
                new Date(client.lastPurchase)
              )} dia(s)`
            : "Hoje"}
        </td>
        <td />
        <td className="text-right">
          <Button
            color="danger"
            id="tooltip264453216"
            type="button"
            onClick={toggle}
          >
            <i className="nc-icon nc-minimal-right" />
          </Button>{" "}
        </td>
      </tr>
      <NotClientModal
        purchase={client}
        modal={modal}
        toggle={toggle}
        cashbackModel={cashbackModel}
      />
    </>
  );
}

export default NotClientRowTable;
