import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  ${(props) =>
    props.height
      ? `height: ${props.height}`
      : "height: -webkit-fill-available"};
`;

export const Text = styled.span`
  display: block;
  max-width: 200px;
  text-align: center;
  font-size: 12px;
  margin-top: 15px;
`;
