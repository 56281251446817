import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import logo from "assets/img/logoclubecliente-prov.png";
import { StyledCollapse } from "../NewSidebar/styles";
import { useAuth } from "context/AuthContext";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: props.user };
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <>
            {prop.show != false ? (
              <li
                className={
                  this.getCollapseInitialState(prop.views) ? "active" : ""
                }
                key={key}
              >
                <a
                  href="#pablo"
                  data-toggle="collapse"
                  aria-expanded={this.state[prop.state]}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState(st);
                  }}
                >
                  {prop.icon !== undefined ? (
                    <>
                      <i className={prop.icon} />
                      <p>
                        {prop.name}
                        <b className="caret" />
                      </p>
                    </>
                  ) : (
                    <>
                      <span className="sidebar-mini-icon">{prop.mini}</span>
                      <span className="sidebar-normal">
                        {prop.name}
                        <b className="caret" />
                      </span>
                    </>
                  )}
                </a>
                <Collapse isOpen={this.state[prop.state]}>
                  <ul className="nav">{this.createLinks(prop.views)}</ul>
                </Collapse>
              </li>
            ) : (
              <></>
            )}
          </>
        );
      }
      return (
        <>
          {prop.show != false ? (
            <li className={this.activeRoute(prop.path)} key={key}>
              <NavLink to={prop.path} activeClassName="">
                {prop.icon !== undefined ? (
                  <>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </>
                ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">{prop.name}</span>
                  </>
                )}
              </NavLink>
            </li>
          ) : (
            <></>
          )}
        </>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          <div style={{ textAlign: "center" }}>
            <img src={logo} height="100px" />
            <h4 style={{ color: "white", marginTop: 0 }}>Clube do Cliente</h4>
          </div>
        </div>
        <div className="sidebar-wrapper" ref="sidebar">
          <div className="user">
            <div className="info">
              <NavLink id="collapse8">
                <div></div>
                <span>
                  {this.state.user.name}
                  <b className="caret" />
                </span>
              </NavLink>
              <StyledCollapse toggler="collapse8">
                <ul className="nav">
                  <li>
                    <NavLink to="/settings" activeClassName="">
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">Meu Perfil</span>
                    </NavLink>
                  </li>
                </ul>
              </StyledCollapse>
            </div>
          </div>
          <Nav>
            <ul className="nav">
              <li className="admin">
                <NavLink to="/reports" activeClassName="">
                  <i className="nc-icon nc-chart-bar-32" />
                  <p>Relatórios</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/history" activeClassName="">
                  <i className="nc-icon nc-refresh-69" />
                  <p>Histórico</p>
                </NavLink>
              </li>
              <li className="admin">
                <NavLink to="/clients" activeClassName="">
                  <i className="nc-icon nc-single-02" />
                  <p>Clientes</p>
                </NavLink>
              </li>
              {this.state.user.settings[0]?.model === "cashback" && (
                <li className="admin">
                  <NavLink to="/products" activeClassName="">
                    <i className="nc-icon nc-bag-16" />
                    <p>Segmentações</p>
                  </NavLink>
                </li>
              )}
              {this.state.user.settings[0]?.model === "points" && (
                <li className="admin">
                  <NavLink to="/promotions" activeClassName="">
                    <i className="nc-icon nc-tag-content" />
                    <p>Ofertas</p>
                  </NavLink>
                </li>
              )}
              <li className="admin">
                <NavLink to="/settings" activeClassName="">
                  <i className="nc-icon nc-settings" />
                  <p>Configurações</p>
                </NavLink>
              </li>
            </ul>
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
