import React, { useRef, useCallback, useEffect } from "react";
import Input from "../../components/Input";
import { Form } from "@unform/web";
import { useAuth } from "../../../context/AuthContext";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Row,
  Card,
  Container,
} from "reactstrap";
import api from "services/api";

const Login = () => {
  const notificationAlert = useRef(null);
  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    if (notificationAlert.current) {
      notificationAlert.current.notificationAlert(options);
    }
  };

  const formRef = useRef(null);
  const { signIn, user, setData } = useAuth();

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const response = await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        notify("tr", "danger", "Falha na autenticação, verifique seus dados.");
      }
    },
    [signIn]
  );

  const searchParams = new URLSearchParams(document.location.search);
  const token = searchParams.get("token");

  const tokenLogin = async () => {
    console.log("start token login");
    const response = await api.get("/store/token", {
      headers: {
        Authorization: `Bearer: ${token}`,
      },
    });

    console.log("res token login > ", response.data);

    if (response?.data) {
      setData((state) => {
        return { ...state, user: response.data };
      });
      localStorage.setItem("@clube:user", JSON.stringify(response.data));
    }
  };

  useEffect(() => {
    console.log("----- Rendered Route -----");
    if (token) {
      console.log("token received > ", token);
      setData((state) => {
        return { ...state, token: token };
      });
      localStorage.setItem("@clube:token", token);
      tokenLogin();
    }
  }, []);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    {/* <div style={{ textAlign: "center" }}>
                      <img
                        src={logo}
                        alt="Logo takeat Dashboard"
                        style={{ width: 200, height: 54 }}
                      />
                    </div> */}

                    <h3 className="header text-center">Login</h3>
                  </CardHeader>
                </CardHeader>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <CardBody>
                    <Input
                      name="email"
                      label="E-mail"
                      type="email"
                      placeholder="Seu e-mail"
                    />

                    <Input
                      name="password"
                      type="password"
                      label="Senha"
                      placeholder="Sua senha"
                    />
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="danger"
                      type="submit"
                    >
                      Login
                    </Button>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>

        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/backgroundLogin.webp")})`,
          }}
        />
      </div>
    </>
  );
};

export default Login;
