import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useAuth } from "../../context/AuthContext";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
} from "reactstrap";

const AdminNavbar = (handleMiniClick, ...props) => {
  const [state, setState] = useState({
    collapseOpen: false,
    color: "navbar-transparent",
    user: props.user,
  });

  // this function opens and closes the sidebar on small devices
  function toggleSidebar() {
    document.documentElement.classList.toggle("nav-open");
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  function toggleCollapse() {
    let newState = {
      collapseOpen: !state.collapseOpen,
    };
    if (!state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    setState(newState);
  }
  const { signOut } = useAuth();

  return (
    <>
      <Navbar
        className={classnames("navbar-absolute fixed-top", state.color)}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            {/* <div className="navbar-minimize">
              <Button
                className="btn-icon btn-round"
                color="default"
                id="minimizeSidebar"
                onClick={handleMiniClick}
              >
                <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
              </Button>
            </div> */}
            <div
              className={classnames("navbar-toggle", {
                toggled: state.sidebarOpen,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="dashboard">
              <span className="d-none d-md-block">Dashboard</span>
              <span className="d-block d-md-none">Dashboard</span>
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={state.collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            // data-target="#navigation"
            data-toggle="collapse"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={state.collapseOpen}
          >
            <Nav navbar>
              <UncontrolledDropdown className="btn-rotate" nav>
                <DropdownToggle
                  aria-haspopup={true}
                  caret
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                >
                  <i className="nc-icon nc-settings-gear-65" />
                  <p>
                    <span className="d-lg-none d-md-block">Ações</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem href="profile">Perfil</DropdownItem>
                  <DropdownItem onClick={signOut}>Sair</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
