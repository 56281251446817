import React, {useState, useCallback} from "react";
import { Form } from "@unform/web";

// reactstrap components
import {
  Button,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  Label
} from "reactstrap";
import Input from "../Input";
import {BiTrashAlt, BiEdit} from 'react-icons/bi';

import formatPhoneString from '../../../services/formatPhoneString';
import formatCompleteDate from '../../../services/formatCompleteDate';
import api from '../../../services/api';

function ProductRowTable({product, notify, getProducts, handleDelete}) {
    
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  
  const token = localStorage.getItem("@clube:token");

  const editProduct = useCallback(async (data) => {
    try {
      if(data.name === ''){
        notify("tr", "danger", "Preencha o nome da oferta.");
      }else{
        await api.put(`/store/products/${product.id}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setModalEdit(false);
        getProducts();
        notify("tr", "success", "Segmentação atualizada com sucesso.");
      }
    } catch (err) {
      notify("tr", "danger", "Erro ao editar segmentação.");
    }
  }, [token]);

    return (
      <>
        <tr style={{fontSize: 12}}>
            <td>
                {/* <div className="img-container">
                <img
                    alt="..."
                    src={require("assets/img/dipirona.webp")}
                />
                </div> */}
            </td>
            <td className="td-name">
                <h5>{product.name}</h5>
            </td>
            <td />
            <td className="td-number">
                {product.indexClients.length}
                <br />
                <small>Clientes já compraram essa segmentação</small>
            </td>
            <td className="td-number">
                {product.purchases.length}
                <br />
                <small>Compras com essa segmentação</small>
            </td>
            <td />

            <td className="text-right">
                <Button
                color="info"
                id="tooltip264453216"
                type="button"
                onClick={toggle}
                >
                <i className="fa fa-user" /> Relação de Clientes
                </Button>{" "}
            </td>
            <td className="td-actions">
            <div>
                <div>
                  <Button
                  className="btn-neutral"
                  color="default"
                  data-placement="left"
                  id={`delete${product.id}`}
                  title=""
                  type="button"
                  onClick={() => handleDelete(product.id)}
                  >
                  <BiTrashAlt size={18} color="#dc3545"/>
                  </Button>
                  <UncontrolledTooltip
                  delay={0}
                  placement="left"
                  target={`delete${product.id}`}
                  >
                  Excluir Segmentação
                  </UncontrolledTooltip>
                </div>
                <div>
                  <Button
                  className="btn-neutral"
                  color="default"
                  data-placement="left"
                  id={`edit${product.id}`}
                  title=""
                  type="button"
                  onClick={() => {toggleEdit()}}
                  >
                  <BiEdit size={18} color="#08839B"/>
                  </Button>
                  <UncontrolledTooltip
                  delay={0}
                  placement="left"
                  target={`edit${product.id}`}
                  >
                  Editar Segmentação
                  </UncontrolledTooltip>
                </div>
              </div>
            </td>
        </tr>
              
        <Modal isOpen={modal} toggle={toggle} size='lg'>
            <ModalBody>
                <Row>
                    <Col md="9">
                    <h3>Clientes</h3>
                    </Col>
                    {/* <Col md="3" className="ml-auto" >
                    <Button color="success">
                        Enviar Promoção
                    </Button>
                    </Col> */}
                </Row>
              <Table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Telefone</th>
                    <th className="text-center">Vezes que Comprou</th>
                    <th className="text-right">Última Visita</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    product.indexClients.map(client => 
                      <tr>
                        <td>{client.name || "Cliente não cadastrado"}</td>
                        <td>{formatPhoneString(client.phone)}</td>
                        <td className="text-center">{client.count}</td>
                        <td className="text-right">{formatCompleteDate(client.last)}</td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Modal isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader>
              Editar Segmentação
            </ModalHeader>
            <ModalBody>
              <Form initialData={product} onSubmit={editProduct}>
                <Row>
                  <Col>
                    <Label>Nome: </Label>
                    <Input name="name" type="text"></Input>
                  </Col>
                </Row>
                <Row md="12">
                  <Col md="12">
                  <Button style={{float: 'right', background: 'green'}} type="submit">Cadastrar</Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </>
    );
}

export default ProductRowTable;
