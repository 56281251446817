import styled from "styled-components";

export const Container = styled.div`
  padding-top: 20px;
  align-self: center;
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 30px;

  label {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    img {
      height: auto;
      width: 100%;
      min-width: 200px;
      border-radius: 0px;
      border: 3px solid rgba(255, 255, 255, 0.3);
      background: #eee;
    }
    input {
      display: none;
    }
  }
`;

export const DefaultAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 120px;
  border-radius: 10px;
  /* border: 2px dashed #ddd; */
  background: #fff;
  span {
    color: #ddd;
    font-size: 16px;
    font-weight: bold;
  }
`;
