
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Container } from './styles';

const DoughnutChart = ({ data }) => {

  const options = {
    legend: {
      display: true,
    },
    tooltips: {
      enabled: true,
    },
    cutout: '90%',
  }

  return (
    <Container>
      <Doughnut data={data} options={options}/>
    </Container>
  );
};

export default DoughnutChart;