import React, {useState} from "react";

// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";

import {
    PieChartDiv,
    HeaderLegend,
    ChartsArea,
    PieChartPayments,
    BarChartDiv,
    Ratings,
    ButtonTab,
    PieChartPromotion,
    PieChartDivPromotion
  } from './styles';

import LineChart from '../Reports/LineChart'
import RadarChart from '../Reports/RadarChart';
import PieChart from '../Reports/PieChart';
import BarChart from '../Reports/BarChart';

function ProductsReport({product}) {
    
    return (
      <>
        <Row style={{paddingTop: 20}}>
                <Col md="8">
                  <Row>
                    {/* <Col md="3">
                      <img
                          alt="..."
                          src={promotion.promotion.avatar ? promotion.promotion.avatar.url : null}
                          style={{width: 120, height: 120, borderRadius: '50%'}}
                      />
                    </Col> */}
                    <Col md="12" style={{textAlign: 'left'}}>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        {product.ranking === 1 ? <>
                        <h2 style={{fontSize: 25}}>
                          <i className="nc-icon nc-trophy" style={{color: '#CC8F00'}}/> 
                        </h2>
                        <h2 style={{fontSize: 28, marginLeft: 15, color: '#CC8F00'}}>
                          1º.
                        </h2>
                        </> : 
                        <>
                            <h2 style={{fontSize: 28,}}>
                            {product.ranking}º.
                            </h2>
                        </>}
                        <h2 style={{fontSize: 25, marginLeft: 5}}>
                          {product.product.name}
                        </h2>
                      </div>
                      
                      {product.ranking === 1 &&
                        <p style={{color: '#CC8F00', marginTop: -30}}>
                            Essa é sua segmentação mais comprada!
                        </p>
                      }

                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <h2 style={{color: ''}}>
                          {product.count}
                        </h2>
                        <p style={{ marginLeft: 5, marginTop: 18}}>compras</p>
                      </div>

                      <p style={{marginTop: -30}}>
                        Essa segmentação representa {product.percent}% das compras de seu estabelecimento.
                      </p>
                      
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <PieChartDivPromotion>
                        <LineChart
                          data={{
                            labels: product.monthData,
                            datasets: [
                              {
                                label: 'Compras',
                                data: product.rescueData,
                                backgroundColor: 'blue',
                                borderColor: 'blue',
                                borderWidth: 1,
                                tension: 0.4,
                              },
                            ],
                          }}
                        />
                      </PieChartDivPromotion>
                    </Col>
                  </Row>
                </Col>
                
                <Col md="4">
                  <PieChartPromotion style={{marginTop: 10}}>
                    <PieChart
                      style={{
                        heigth: 300
                      }}
                      data={{
                        labels: ['Masculino', 'Feminino', 'Outros'],
                        datasets: [
                          {
                            data: product.gender,
                            backgroundColor: ['#3B8EA5', '#e83e8c', '#B60AFF'],
                            tension: 0.4,
                          }
                        ],
                      }}
                    />
                    <div style={product.styleGender}>
                        <p style={{textAlign: 'center'}}>{product.percentGender}% {product.phraseGender}</p>
                    </div>
                  </PieChartPromotion>
                  </Col>
              </Row>
              <Row>
              <Col md="6">
                  <PieChartDiv >
                    <HeaderLegend>Bairros</HeaderLegend>
                    <RadarChart
                      data={{
                        labels: product.bairrosLabel,
                        datasets: [
                          {
                            label: 'Clientes',
                            data: product.bairrosData,
                            backgroundColor: '#007C7750',
                            borderColor: '#007C77',
                            borderWidth: 1,
                            fill: true
                          },
                        ],
                      }}
                    />
                  </PieChartDiv>
                </Col>
                  <Col md="6">
                    <BarChartDiv style={{marginTop: 10}}>
                      <HeaderLegend>Idade do Público dessa Segmentação</HeaderLegend>
                      <BarChart
                        data={{
                          labels: ['0 - 9', '10 - 19', '20 - 29', '30 - 39', '40 - 49', '50 - 59', '60 - 69', '70 - 79', '80 - 89', '90 - 99'],
                          datasets: [
                            {
                              label: 'Quantidade de Clientes',
                              backgroundColor: [
                              '#2a778e',
                              '#009684',
                              '#226666',
                              '#669999',
                              '#407F7F',
                              '#0D4D4D',
                              '#003333',
                              '#27556C',
                              '#6E91A1',
                              '#477286'],
                              data: product.ages,
                              tension: 0.4,
                            }
                          ],
                        }}
                      />
                      </BarChartDiv>
                </Col>
            </Row>
            <Row>
                <Col>
                  <div style={{borderBottom: '2px solid #D9D6D4', marginTop: 30}}>
                  </div>        
                </Col>
            </Row>
        </>
    );
}

export default ProductsReport;
