import styled from "styled-components";
import { Link } from "react-router-dom";

export const LinkStyled = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const ShoppingInfos = styled.div`
  padding: 20px;
  place-content: center;
  display: flex;
  flex-direction: row;
`;

export const DateSelectorDiv = styled.div`
  width: 90%;
  height: 60px;
  display: flex;
  clear: both;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
  flex-direction: row;
`;

export const DateDiv = styled.div`
  float: left;
  left: 0;
  margin-left: 15px;
`;

export const SubmitDate = styled.button`
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;

  div {
    display: flex;
    align-items: center;
    gap: 5px;

    cursor: pointer;
  }

  span {
    line-height: 14px;
  }

  .PrivateSwitchBase-root-1 {
    width: 24px;
    height: 24px;
  }
`;

export const Preview = styled.p`
  width: 100%;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  pointer-events: none;
`;

export const WhatsppDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;
