import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import Login from "../views/pages/Login";
import Dashboard from "../views/Dashboard.jsx";
import Settings from "views/pages/Settings.jsx";
import Products from "views/pages/Products";
import Clients from "views/pages/Clients";
import Promotions from "views/pages/Promotions";
import Reports from "views/pages/Reports";
import { useAuth } from "context/AuthContext";
import api from "services/api";
import { FromTakeat } from "views/pages/FromTakeat";

export default function Routes() {
  return (
    <Switch>
      {/* <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} /> */}
      <Route path="/" exact component={Login} />
      <Route path="/redirect/:token" exact component={FromTakeat} />
      <Route path="/history" exact component={Dashboard} isPrivate />
      <Route path="/products" exact component={Products} isPrivate />
      <Route path="/promotions" exact component={Promotions} isPrivate />

      <Route path="/clients" exact component={Clients} isPrivate />
      <Route path="/reports" exact component={Reports} isPrivate />
      <Route path="/settings" component={Settings} isPrivate />
    </Switch>
  );
}
