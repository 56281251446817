
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PieChartDiv = styled.div`
  height: 350px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  & + div {
    margin-left: 15px;
  }
`;

export const PieChartDivPromotion = styled.div`
  height: 250px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  & + div {
    margin-left: 15px;
  }
`;



export const ButtonTab = styled(Link)`
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 10px 30px 10px 30px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 40px;

  ${({ thisButton, activeButton }) => activeButton === thisButton && `
    border-bottom: red 3px solid;
  `}

  :hover {
    border-bottom: red 2px solid;
    color: inherit; /* blue colors for links t */
    text-decoration: inherit; /* no underline */
  }

  :focus {
    color: inherit; /* blue colors for links t */
    text-decoration: inherit; /* no underline */
  }

`;

export const DoughnutChartDiv = styled.div`
  height: 450px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  & + div {
    margin-left: 15px;
  }
`;

export const ChartsArea = styled.div`
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
`;

export const HeaderLegend = styled.div`
  width: 100%;
  height: 20px;
  color: #3D3D3D;
  margin-bottom: 10px;
  justify-content: center;
  text-align: center;
  font-size: 17px;
  display: flex;
  first-child {
    border-right: 1px solid #dee2e6;
  }
`;

export const Ratings = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  & + div {
    margin-left: 15px;
  }
`;

export const PieChartPayments = styled.div`
  margin: auto;
  margin-left: 20px;
  background-color: #fff;
  border-radius: 7px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  padding: 15px;
  text-align: left;
`;

export const PieChartPromotion = styled.div`
  margin: auto;
  margin-left: 20px;
  background-color: #fff;
  border-radius: 7px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  padding: 15px;
  text-align: left;
`;

export const BarChartDiv = styled.div`
  background-color: #fff;
  border-radius: 7px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  padding: 15px;
  text-align: left;
`;

