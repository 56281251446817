import React, { useState, useEffect } from "react";
import { differenceInDays } from "date-fns";
import { lighten } from "polished";
// reactstrap components
import { Table, Row, Modal, ModalBody, Col } from "reactstrap";
import { useAuth } from "../../../context/AuthContext";
import formatCompleteDate from "../../../services/formatCompleteDate";
import formatPhoneString from "../../../services/formatPhoneString";
import api from "services/api";

function NotClientModal({ purchase, modal, toggle }) {
  const { user } = useAuth();
  const token = localStorage.getItem("@clube:token");
  const settings = user.settings[0];
  const cashbackModel = settings?.model === "cashback" ? true : false;

  const [client, setClient] = useState(null);

  async function getClient() {
    const client = await api.get(`/store/clients/${purchase.phone}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setClient(client.data);
  }

  useEffect(() => {
    if (modal) {
      getClient();
    }
  }, [modal]);
  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <ModalBody
        style={{
          borderRadius: "0.3rem 0.3rem 0.3rem 0.3rem",
          backgroundColor: "#F1B2A7",
        }}
      >
        {client !== null && (
          <>
            <Row>
              <Col md="9">
                <h3>{formatPhoneString(purchase.phone)}</h3>
                <p>Cliente não cadastrado</p>
              </Col>
              {/* <Col md="3" className="ml-auto" >
            <Button color="success">
                Enviar Promoção
            </Button>
          </Col> */}
            </Row>

            <Row style={{ paddingTop: 10 }}>
              <Col md="12">
                {settings?.model === "cashback" ? (
                  <>
                    <strong style={{ color: "green" }}>
                      Cashback Disponível: R${client?.cashback}
                    </strong>
                  </>
                ) : (
                  <>
                    <strong style={{ color: "green" }}>
                      Pontos Disponíveis: {client?.points} pontos
                    </strong>
                  </>
                )}
              </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}>
              <Col md="12">
                <strong>Produto Favorito: </strong>{" "}
                {client.allProducts ? client.allProducts[0].name : "-"}
              </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}>
              <Col>
                <strong>Gasto em seu estabelecimento: </strong> R$
                {client?.totalSpend}
              </Col>
            </Row>
            <Row style={{ paddingTop: 10, paddingBottom: 20 }}>
              <Col>
                <strong>Comprou em seu estabelecimento: </strong>{" "}
                {client?.purchases.length} vez(es).
              </Col>
            </Row>

            <h5>
              <i className="nc-icon nc-user-run" /> Visitas
            </h5>
            <Table>
              <thead>
                <tr>
                  <th>Valor Gasto</th>
                  <th>Produtos</th>
                  <th>Cashback</th>
                  <th className="text-center">Data</th>
                  <th className="text-right">Dias</th>
                </tr>
              </thead>
              <tbody>
                {client?.purchases.map((purchase) => (
                  <tr>
                    <td>R${purchase.total}</td>
                    <td>{purchase.products?.length}</td>
                    <td>
                      {cashbackModel
                        ? `R$${purchase.cashback}`
                        : `${purchase.points} ponto(s)`}
                    </td>
                    <td className="text-center">
                      {formatCompleteDate(purchase.createdAt)}
                    </td>
                    <td className="text-right">
                      {differenceInDays(
                        new Date(),
                        new Date(purchase.createdAt)
                      ) > 0
                        ? `há ${differenceInDays(
                            new Date(),
                            new Date(purchase.createdAt)
                          )} dia(s)`
                        : "Hoje"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <h5>
              <i className="nc-icon nc-bag-16" /> Produtos
            </h5>
            <Table>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Quantidade de Compras</th>
                  <th className="text-center">Data da Última Compra</th>
                  <th className="text-right">Dias</th>
                </tr>
              </thead>
              <tbody>
                {client?.allProducts?.map((product) => (
                  <tr>
                    <td>{product.name}</td>
                    <td>{product.count}</td>
                    <td className="text-center">
                      {formatCompleteDate(product.last)}
                    </td>
                    <td className="text-right">
                      {differenceInDays(new Date(), new Date(product.last)) > 0
                        ? `há ${differenceInDays(
                            new Date(),
                            new Date(product.last)
                          )} dia(s)`
                        : "Hoje"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

export default NotClientModal;
