import React from "react";
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from "react-router-dom";
import { useAuth } from "../context/AuthContext";

import Auth from "../layouts/Auth";
import Admin from "../layouts/Admin";

export default function Route({
  isPrivate = false,
  component: Component,
  ...rest
}) {
  const { user, setData } = useAuth();

  const Layout = !!user ? Admin : Auth;

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return isPrivate === !!user ? (
          <Layout user={user}>
            {console.log("got inside layout")}
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? "/" : "/reports",
            }}
          />
        );
      }}
    />
  );
}
