import styled from "styled-components";

export const Container = styled.div`
  align-self: center;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  label {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 3px solid rgba(255, 255, 255, 0.3);
      background: #eee;
    }
    input {
      display: none;
    }
  }
`;

export const DefaultAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border: 1px solid #ddd;
  border-radius: 50%;
  background: #fff;
  span {
    color: #ddd;
    font-size: 10px;
    text-align: center;
    font-weight: bold;
  }
`;
