import React from "react";
import { Spinner } from "reactstrap";
import { Container, Text } from "./styles";

export const Loader = ({ text, height }) => {
  return (
    <Container height={height}>
      <Spinner style={{ color: "rgb(0,0,0,0.5)" }} />
      {text && <Text>{text}</Text>}
    </Container>
  );
};
