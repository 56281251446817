import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { ButtonLink } from "./styles";

export default function CardDash({
  title,
  total,
  icon,
  toggle,
  details,
  useIcon,
  info,
  fontSize,
  loading,
}) {
  return (
    <Card className="card-stats" style={{ height: "calc(100% - 20px)" }}>
      <CardBody>
        <Row>
          <Col md="2" xs="5">
            <div className="icon-big text-center icon-warning">
              {useIcon ? icon : <i className={icon} />}
            </div>
          </Col>
          <Col md="10" xs="7">
            <div className="numbers">
              <p className="card-category">
                {title} {info}
              </p>
              {loading ? (
                <Spinner style={{ borderWidth: "3px" }} />
              ) : fontSize ? (
                <CardTitle tag="p" style={{ fontSize: fontSize }}>
                  {total}
                </CardTitle>
              ) : (
                <CardTitle tag="p">{total}</CardTitle>
              )}
              <p />
            </div>
          </Col>
        </Row>
      </CardBody>

      <CardFooter>
        {/* <hr /> */}
        {details && (
          <>
            <div className="stats">
              <i className="fa fa-plus-slim" />
              <ButtonLink onClick={toggle}>Mais detalhes</ButtonLink>
            </div>
          </>
        )}
      </CardFooter>
    </Card>
  );
}
