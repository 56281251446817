import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { format, startOfDay, endOfDay, subHours, startOfWeek, startOfMonth, addHours} from 'date-fns';
import { Form } from "@unform/web";

import {
  // Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  // FormGroup,
  // Input,
  // Table,
  Row,
  Col,
  Collapse,
  Modal,
  ModalBody,
  Table,
  ModalHeader
  // UncontrolledTooltip,
} from "reactstrap";

import api from "../../../services/api";
import NotificationAlert from "react-notification-alert";
import Input from "../../components/Input";
import PromotionsTable from "../../components/PromotionsTable";
import AvatarInput from "components/AvatarInput";

function Promotions(){
  const formRef = useRef(null);
  const notificationAlert = useRef();

  const token = localStorage.getItem("@clube:token");

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const [promotions, setPromotions] = useState([]);

  const getPromotions = useCallback(async () => {
    try {
      const response = await api.get("/store/promotions", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPromotions(response.data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token]);

  const handleNewPromotion = useCallback(async (data) => {
    try {
      data.avatar_id = parseInt(data.avatar);
      console.log(data);
      const response = await api.post("/store/promotions", data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      notify("tr", "success", "Oferta cadastrada com sucesso!");
      setPromotions(response.data);
      toggle();
    } catch (err) {
      notify("tr", "danger", "Erro ao cadastrar.");
    }
  }, [token]);


  const handleDelete = useCallback(async (id) => {
    if(window.confirm('Deseja excluir essa oferta?')){
      try {
        const response = await api.delete(`/store/promotions/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        getPromotions();
        notify("tr", "success", "Oferta removida com sucesso!");
      } catch (err) {
        notify("tr", "danger", "Erro ao remover oferta.");
      }
    }
  }, []);

  useEffect(() => {
    getPromotions();
  }, []);

    return (
      <>
      <NotificationAlert ref={notificationAlert} />
        <div className="content">
          <Row>
            <Col md="9">
            <h2><i className="nc-icon nc-tag-content" />  Ofertas</h2>
            </Col>
            <Col md="3" className="ml-auto" >
              <Button color="danger" onClick={toggle} style={{float: 'right'}}>
              <i className="nc-icon nc-simple-add"/>Nova Oferta
              </Button>
            </Col>
          </Row>

          <Row md="12">
            <Col md="12">           
             <PromotionsTable promotions={promotions} handleDelete={handleDelete} 
              getPromotions={getPromotions} notify={notify}/>
            </Col>
          </Row> 

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>
              Cadastrar Promoção
            </ModalHeader>
            <ModalBody>
              <Form  onSubmit={handleNewPromotion}>
                <Row>
                  <Col md="4" sm="4">
                    <AvatarInput name="avatar" />
                  </Col>
                  <Col>
                    <Label>Promoção: </Label>
                    <Input name="name" type="text"></Input>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Pontos Necessários para Resgate: </Label>
                    <Input name="points" type="number"></Input>
                  </Col>
                </Row>
                <Row md="12">
                  <Col md="12">
                  <Button style={{float: 'right', background: 'green'}} type="submit">Cadastrar</Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
}

export default Promotions;
