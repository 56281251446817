import styled from "styled-components";
import { Link } from "react-router-dom";

export const PieChartDiv = styled.div`
  height: 450px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  & + div {
    margin-left: 15px;
  }
`;

export const ChartsArea = styled.div`
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
`;

export const HeaderLegend = styled.div`
  width: 100%;
  height: 20px;
  color: #3d3d3d;
  margin-bottom: 10px;
  justify-content: center;
  text-align: center;
  font-size: 17px;
  display: flex;
  first-child {
    border-right: 1px solid #dee2e6;
  }
`;

export const Ratings = styled.div`
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
  & + div {
    margin-left: 15px;
  }
`;

export const PieChartPayments = styled.div`
  width: 400px;
  margin: auto;
  margin-left: 20px;
  background-color: #fff;
  border-radius: 7px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  padding: 15px;
  text-align: left;
`;

export const BarChartDiv = styled.div`
  background-color: #fff;
  border-radius: 7px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  padding: 15px;
  text-align: left;
`;
