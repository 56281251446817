import { addHours, format, formatISO, parseISO, subHours } from "date-fns";
import pt from "date-fns/locale/pt";

export default function formatToMonth(date) {
  if (!(date instanceof Date)) {
    date = addHours(parseISO(date), 3);
  }
  return format(date, "MMM", {
    locale: pt,
  });
}
