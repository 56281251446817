import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";
import api from "../services/api";
import WebsocketManager from "websocketManager";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [websocketManager, setWebsocketManager] = useState(null);
  const [wppStatus, setWppStatus] = useState(
    JSON.parse(localStorage.getItem("@clube:wppStatus")) || null
  );
  const [data, setData] = useState(() => {
    const token = localStorage.getItem("@clube:token");
    const user = localStorage.getItem("@clube:user");

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post("public/sessions/store/", {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem("@clube:token", token);
    localStorage.setItem("@clube:user", JSON.stringify(user));

    setData({ token, user });
    return true;
  }, []);

  const getUser = async () => {
    const response = await api.get(`/store/edit/${data.user.id}`, {
      headers: { Authorization: `Bearer: ${data.token}` },
    });

    localStorage.setItem("@clube:user", JSON.stringify(response.data));
    setData((state) => {
      return { ...state, user: response.data };
    });
  };

  const updateSettings = useCallback(async (settings) => {
    const token = localStorage.getItem("@clube:token");

    const response = await api.put("store/settings", settings, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const user = response.data;

    localStorage.setItem("@clube:user", JSON.stringify(user));

    setData({ user, token });
    return true;
  }, []);

  const signOut = useCallback(() => {
    for (const key in localStorage) {
      if (key.substring(0, 7) === "@clube:") {
        localStorage.removeItem(key);
      }
    }

    setData({});
  });

  useEffect(() => {
    const user = data.user;
    if (websocketManager) {
      websocketManager.socket.on("uzapi_status", (data) => {
        console.log("websocket data > ", data);
        if (user.key === data.session) {
          setWppStatus(data.state);
        }
      });
    }
  }, [websocketManager, data]);

  useEffect(() => {
    if (data.user) {
      localStorage.setItem("@clube:user", JSON.stringify(data.user));
    }
    if (data.token) {
      const manager = new WebsocketManager(data.token);

      setWebsocketManager(manager);
    }
  }, [data]);

  useEffect(() => {
    localStorage.setItem("@clube:wppStatus", JSON.stringify(wppStatus));
  }, [wppStatus]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateSettings,
        setData,
        websocketManager,
        wppStatus,
        setWppStatus,
        getUser,
      }} //, updateProfile }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
