import { useAuth } from "context/AuthContext";
import React, { useEffect, useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";

import api from "services/api";
import { Container, Title } from "./styles";
import { Spinner } from "reactstrap";

export const FromTakeat = () => {
  const { setData } = useAuth();
  const [loadingRedirect, setLoadingRedirect] = useState(false);

  const path = window.location.pathname.split("/");
  const token = path[path.length - 1];

  const notificationAlert = useRef(null);
  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const tokenLogin = async () => {
    setLoadingRedirect(true);
    try {
      const response = await api.get("/store/token", {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });

      if (response?.data) {
        setData((state) => {
          return { ...state, user: response.data };
        });
        localStorage.setItem("@clube:user", JSON.stringify(response.data));
      }
    } catch (err) {
      notify("tr", "danger", "Não foi possível realizar o login no clube");
      window.location.pathname = "/";
    }
    setLoadingRedirect(false);
  };

  useEffect(() => {
    if (token) {
      setData((state) => {
        return { ...state, token: token };
      });
      localStorage.setItem("@clube:token", token);
      tokenLogin();
    }
  }, []);

  return (
    <Container>
      <NotificationAlert ref={notificationAlert} />
      <Title>Aguarde enquanto te redirecionamos</Title>
      {loadingRedirect && <Spinner color="red" />}
    </Container>
  );
};
