import React from "react";
import { Bar } from "react-chartjs-2";
import { Container } from "./styles";

const BarChart = ({ data, bairro }) => {
  const options = {
    indexAxis: bairro ? "y" : "x",

    // responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          offset: true,
        },
      },
    },
  };

  return (
    <Container>
      <Bar data={data} options={options} redraw />
    </Container>
  );
};

export default BarChart;
