import React from "react";
import { Pie } from "react-chartjs-2";
import { Container } from "./styles";

const PieChart = ({ data }) => {
  const options = {
    legend: {
      display: true,
    },
    tooltips: {
      enabled: true,
    },
    cutout: "50%",
  };

  return (
    <Container>
      <Pie data={data} options={options} />
    </Container>
  );
};

export default PieChart;
